export default {
  popup: {
    agg_total: 'Total points',
    na: 'N/A'
  },
  navBar: {
    titleTooltip: 'Go to Workspace',
    title: 'CARTO Builder',
    titleMobile: 'Builder',
    updating: 'Autosaving map...',
    updated: 'Updated',
    share: 'Share',
    takeOver: 'Take over editing',
    sharePrivateTooltip: 'Private map',
    shareSharedTooltip: 'Shared map',
    sharePublicTooltip: 'Public map',
    shareDisabledTooltip: 'Give a title to this map before sharing it',
    publishTooltip: 'Publish updates',
    shareTooltip: 'Share map',
    autosaved: 'Autosaved:',
    autosavedDisabled: {
      title: 'Autosaving disabled',
      msg: 'There are errors in one or more data sources. Please fix them to enable autosaving again.'
    },
    beingEditedBySomeone: 'Being edited by {user}',
    someoneFinishedEditing: '{user} just finished editing',
    skipToWidgets: 'Skip to widgets',
    skipToMap: 'Skip to map',
    skipToNextWidget: 'Skip to next widget',
    skipToLastWidget: 'Last widget',
    skipToNextParameter: 'Skip to next parameter',
    skipToLastParameter: 'Last parameter'
  },
  actions: {
    preview: 'Preview',
    edit: 'Edit',
    credentials: {
      remove: 'Remove credentials'
    },
    export: {
      title: 'Export viewport data',
      noDatasetsTooltip: 'Add data to this map before exporting it',
      noDatasetsTooltipPDF: 'Add data to this map before downloading it',
      noExportableLayersTooltip: 'Export unavailable for current layers',
      noExportableLayersDueToLegendTooltip: 'Add layer to the map layer list to export',
      dialog: {
        title: 'Export data from viewport',
        someLayersNotExportable: 'Some layer(s) from your viewport cannot be exported.',
        limitAlert: 'There is a limit of {limit} layers per export.',
        referToDoc: 'Refer to our documentation to understand export limitations.',
        placeholderWithLimit: 'Select up to {limit} layers',
        content: `Download data from the map's layers. The resulting CSV file(s) will include data from the features currently present within the viewport extent.`,
        layers: 'Layers to download',
        fileFormat: 'File format',
        confirm: 'Export data',
        disabledNotExportablePostgres: 'Non-exportable PostgreSQL layer',
        disabledTileset: 'Tileset layers are non-exportable',
        disabledRaster: 'Raster layers are non-exportable',
        disabledMaxLayers: 'Limit of {limit} layers per export. Please deselect others to add this one.'
      },
      success: 'Data exported successfully',
      failure: 'Failed to export layer data',
      noLayers: 'No layers were found in the viewport to export',
      noDataInLayer: 'No data in visible area of this layer'
    },
    duplicate: {
      title: 'Duplicate map',
      disabledTooltip: 'Give a title to this map before duplicating it',
      dialog: {
        title: 'Duplicate map',
        content:
          "Some <strong>data sources</strong> won't be successfully loaded on the new map, please check the reasons below:",
        conflictDetails: 'The following connections are not shared with you:',
        continue: 'Continue anyway',
        cancel: 'Cancel'
      },
      success: 'Map duplicated successfully',
      successWithLink: 'Map duplicated successfully, ',
      successLinkLabel: 'click here to view it'
    },
    delete: {
      title: 'Delete map'
    },
    myLocation: {
      tooltip: 'Show your current location',
      loading: 'Obtaining your location...',
      unavailable: 'Location unavailable',
      noPermission:
        "CARTO doesn't have permission to use your location. You can change the permissions in your browser settings."
    },
    sharingOptions: 'Sharing options',
    publishUpdates: 'Publish updates',
    copyLink: 'Copy link',
    linkCopiedToClipboard: 'Link copied to clipboard!'
  },
  exportPDF: {
    title: 'Download PDF Report',
    commentsDialog: {
      label: 'Comments',
      placeholder: 'Add a comment to the report',
      description: 'Do you want to add some comments to the PDF report?',
      cancel: 'Cancel',
      preview: 'Preview'
    },
    previewDialog: {
      backToBuilder: 'Back to Builder',
      backToMap: 'Back to map',
      download: 'Download PDF Report',
      title: 'Title',
      date: 'Report date',
      comments: 'Comments',
      description: 'Description',
      legend: 'Legend',
      parameters: 'Parameters',
      values: 'Values',
      selectedValue: 'Selected value',
      selectedValues: 'Selected values',
      startDate: 'Start date',
      endDate: 'End date',
      min: 'Min',
      max: 'Max',
      widgets: 'Widgets',
      print: 'Print',
      nTableColumnsDisplayed: '{n} of {total} columns displayed',
      nTableRows: 'Rows 1-{n} of {total}'
    },
    screenshot: {
      loading: 'Loading screenshot...'
    }
  },
  mobileModal: {
    title: 'Are you on mobile?',
    text: `CARTO Builder® currently has limited map edition capabilities on mobile devices.<br /><br />If you are already on a desktop device, please try a bigger window size or higher resolution.`,
    notShowAgain: 'Do not show this message again',
    accept: 'Understood'
  },
  layerPanel: {
    addInfo: 'Add a data source below to create map layers',
    loadingDataSources: 'Waiting for data source to show map layers',
    missingLayers:
      '{n, plural, one {# layer is not displayed due to error on its data source. Please fix it to display the layer again} other {# layers are not displayed due to errors on their data sources. Please fix them to display the layers again}}.',
    dataClassificationMethod: 'Data classification method',
    dataClassificationInfo: 'To modify the colors and steps number, adjust them in the palette field above.',
    values: 'values',
    picker: 'Color picker',
    preset: 'Preset colors',
    resolution: 'Resolution',
    resolutionHelp: 'The resolution level controls the granularity of the aggregation',
    aggregateByGeometry: 'Aggregate by geometry',
    aggregateByGeometryTooltip:
      'Aggregate your layer by identical geometries. Properties will be aggregated for styling and interactions.',
    cartoColors: {
      title: 'CARTOColors',
      raster: 'Raster palettes',
      organization: 'Organization palettes',
      colorDeletedFromOrganization:
        "The palette is no longer accessible. Contact your organization's Admin to restore access.",
      others: 'Other palettes',
      learnMore: 'Learn more'
    },
    customMarkers: {
      singleMarker: 'Single Marker',
      selectMarker: 'Select Marker',
      markerFilePlaceholder: 'E.g.: file.svg, file.png',
      selectFromLibrary: 'Select from library',
      uploadMarker: 'Upload Marker',
      assignMarkersAs: 'Assign Markers As',
      imageResolutionExceededError: 'The file is too large. Allowed maximum resolution is {size}x{size}px',
      fileTooLargeError: 'Allowed maximum size is {size}',
      invalidMarker: 'Invalid marker',
      invalidMarkersToolip: 'There are some invalid markers',
      changeIcon: 'Change icon'
    },
    hexColumnConfig: {
      label: 'Color Field',
      help: "Apply a color based on Hex code column's values"
    },
    channelSelector: {
      noSuitableFields: 'No suitable columns in dataset. Supported types: {types}.'
    },

    visualization: 'Visualization',
    advancedVisualizationOptions: 'Advanced visualization options',
    opacity: 'Opacity',

    fill: 'Fill',
    advancedFillOptions: 'Advanced fill options',
    addFill: 'Add fill',
    removeFill: 'Remove fill',
    color: 'Color',
    palette: 'Palette',
    colorSchemeBy: 'Color scheme by',
    colorScale: 'Color scale',
    solid: 'Solid',
    simple: 'Simple',
    fixed: 'Fixed',

    stroke: 'Stroke',
    advancedStrokeOptions: 'Advanced stroke options',
    addStroke: 'Add stroke',
    removeStroke: 'Remove stroke',
    strokeColor: 'Stroke color',
    strokeWeight: 'Stroke weight',
    weightRangeBy: 'Weight range by',
    weightScale: 'Weight scale',
    point: 'Point',
    customMarker: 'Custom marker',

    height: 'Height',
    advancedHeightOptions: 'Advanced height options',
    addHeight: 'Add height',
    removeHeight: 'Remove height',
    heightBasedOn: 'Height based on',
    heightScale: 'Height scale',
    showWireframe: 'Show wireframe',
    activate3dAlert: 'Active 3D view on the “Map View” toolbar above the map.',

    labels: 'Labels',
    advancedLabelsOptions: 'Advanced labels options',
    addLabels: 'Add labels',
    removeLabels: 'Remove labels',
    single: 'Single',
    double: 'Double',
    text: 'Text',
    textColor: 'Text color',
    haloColor: 'Halo color',
    mainText: 'Main text',
    mainHaloColor: 'Main halo color',
    secondaryText: 'Secondary text',
    secondaryHaloColor: 'Secondary halo color',
    placement: 'Placement',

    symbol: 'Symbol',
    advancedSymbolOptions: 'Advanced symbol options',
    marker: 'Marker',
    multipleMarkersBy: 'Multiple markers by',
    size: 'Size',
    radius: 'Radius',
    radiusRangeBy: 'Radius range by',
    rotation: 'Rotation',
    none: 'None',
    markerRotationBy: 'Marker rotation by',

    cell: 'Cell',
    aggregationSize: 'Aggregation size',
    aggregationSizeTooltip: {
      spatialIndex:
        'Adjust the level of detail for spatial index aggregation. Higher values provide more detailed aggregation.',
      cluster: 'Adjust the level of detail for cluster aggregation. Lower values result in more granular clustering.'
    },

    areaOfInfluence: 'Area of influence',
    advancedAreaOfInfluenceOptions: 'Advanced area of influence options',
    radiusTooltip: 'Increasing radius smooths the heatmap but reduces detail',
    weight: 'Weight',
    weightBy: 'Weight by',
    uniform: 'Uniform',
    uniformSubtitle: 'All points are weighted equally',

    style: 'Style',
    uniqueValuesDisabled: 'Not supported for rasters lacking discrete numeric bands',
    restoreStyle: 'Restore style',
    styleToolip: 'Advanced style options',
    colorRange: 'Color range',
    unique: 'Unique',
    rgb: 'RGB'
  },
  spatialData: {
    spatialType: 'Spatial type',
    spatialColumn: 'Spatial column',
    spatialColumnPlaceholder: 'Select a column',
    infoAlert: {
      title: 'Define spatial data',
      message: 'To view the layer, please select a valid spatial column and type.'
    },
    errorHelperText: 'Select a valid column and type.',
    errorAlert: {
      title: 'Invalid spatial data',
      message: 'Please select a valid spatial data column and type to resolve this error.'
    },
    applySelection: 'Apply selection',
    learnMore: 'Learn more about spatial data in our <docLink>Documentation</docLink>'
  },
  layerPanelHeader: {
    actionsTooltip: 'More options',
    hideLayerTooltip: 'Hide layer',
    showLayerTooltip: 'Show layer',
    actions: {
      style: 'Layer style',
      duplicate: 'Duplicate',
      rename: 'Rename',
      delete: 'Delete',
      showOnlyThis: 'Show only this',
      showAllLayers: 'Show all layers',
      zoomToLayer: 'Zoom to'
    },
    disabledTooltips: {
      zoomToLayer: 'Not supported for this layer'
    },
    errorDetailsTooltip: 'Show error details',
    selected: {
      back: 'Back',
      title: 'Layer style'
    },
    layerType: 'Layer type',
    layerVisibility: {
      title: 'Visibility by zoom level',
      tooltip: 'This layer will only be visible in the selected zoom levels'
    },
    type: {
      line: 'line',
      point: 'point',
      polygon: 'polygon',
      grid: 'grid',
      h3: 'h3',
      tileset: 'tileset',
      heatmapTile: 'heatmap',
      clusterTile: 'cluster'
    },
    spatialIndexInfo: {
      title:
        "You're working with a {actionLink}, data properties need to be aggregated to be used in the map, interactions and widgets.",
      grid: 'grid',
      notShow: "Don't show me again"
    },
    pointTileLayerSpatialIndexHint: `Large point datasets are better visualized as an aggregated grid. Select "Quadbin" below to dynamically aggregate your points leveraging this spatial index.`,
    aggregated: 'Aggregated',

    UNKNOWN: 'UNKNOWN',
    ERROR: 'ERROR',
    unknown: 'Unknown',
    openToFix: 'Open to fix',
    openToDefine: 'Open to define',
    fixOnSqlEditor: 'Fix on SQL Editor'
  },
  datasetLabels: {
    tileset: 'Tileset',
    geom: 'Geometry',
    h3: 'H3',
    quadbin: 'Quadbin',
    raster: 'Raster'
  },
  layerTypes: {
    tilesetPoint: {
      label: 'Point',
      title: 'Geometry without aggregation'
    },
    aggregatedTilesetPoint: {
      label: 'Point',
      title: 'Aggregated by geometry'
    },
    tilesetPolygon: {
      label: 'Polygon',
      title: 'Geometry without aggregation'
    },
    aggregatedTilesetPolygon: {
      label: 'Polygon',
      title: 'Aggregated by geometry'
    },
    tilesetLine: {
      label: 'Line',
      title: 'Geometry without aggregation'
    },
    aggregatedTilesetLine: {
      label: 'Line',
      title: 'Aggregated by geometry'
    },
    quadbin: {
      label: 'Grid',
      title: 'Aggregated into grid cells'
    },
    quadbinGeom: {
      label: 'Grid',
      title: 'Aggregated geometry into grid cells'
    },
    h3: {
      label: 'H3',
      title: 'Aggregated into hexagonal bins'
    },
    h3Geom: {
      label: 'H3',
      title: 'Aggregated geometry into hexagonal bins'
    },
    heatmap: {
      label: 'Heatmap',
      title: 'Aggregated geometry by density'
    },
    cluster: {
      label: 'Cluster',
      title: 'Aggregated geometry into circles'
    },
    raster: {
      label: 'Raster',
      title: 'Raster with overviews'
    },
    rasterNoOverviews: {
      label: 'Raster',
      title: 'Raster without overviews'
    }
  },
  layerAggregateByGeometryConfirmDialog: {
    title: 'Aggregate by geometry',
    message:
      'You are about to change the visualization type of your layer to aggregate by identical geometries.\n\nPlease note that some map components associated with this layer source cannot be preserved and will be affected by this change:',
    alert: 'The following elements will be removed from the map'
  },
  layerTypeChangeConfirmDialog: {
    spatialDefinitionChange: {
      title: 'Spatial Definition Change',
      message:
        'You are about to change the spatial definition of your source. Please note that some map components associated with this source cannot be preserved and will be affected by this change:'
    },
    quadbin: {
      title: 'Aggregated grid layer',
      message:
        'You are about to change the visualization type of your layer to aggregated grid. Please note that some map components associated with this layer source cannot be preserved and will be affected by this change:'
    },
    h3: {
      title: 'Aggregated h3 layer',
      message:
        'You are about to change the visualization type of your layer to aggregated h3. Please note that some map components associated with this layer source cannot be preserved and will be affected by this change:'
    },
    tileset: {
      title: 'Point layer',
      message:
        'You are about to change the visualization type of your layer to point. Please note that some map components associated with this layer source cannot be preserved and will be affected by this change:'
    },
    heatmapTile: {
      title: 'Aggregated heatmap layer',
      message:
        'You are about to change the visualization type of your layer to aggregated heatmap. Please note that some map components associated with this layer source cannot be preserved and will be affected by this change:'
    },
    clusterTile: {
      title: 'Aggregated cluster layer',
      message:
        'You are about to change the visualization type of your layer to aggregated cluster. Please note that some map components associated with this layer source cannot be preserved and will be affected by this change:'
    },
    query: {
      title: 'Query this table',
      message: 'Visualizing your data as dynamic tileset will affect following map properties:'
    },
    alertHeader: 'The following elements will be removed from the map'
  },
  layerConfigGroup: {
    uncollapseTooltip: 'More options',
    addTooltip: {
      fillColor: 'Add fill color',
      outline: 'Add outline',
      stroke: 'Add stroke',
      height: 'Add height'
    }
  },
  loaderSelector: {
    title: 'Sources',
    addSource: {
      tooltip: 'Add source from...'
    },
    sqlParameters: {
      tooltip: 'Create a SQL Parameter',
      tooltipDisabled: 'Add a SQL Query data source to enable SQL Parameters'
    },
    dataFreshness: 'Data freshness',
    refreshDataSources: 'Refresh data sources'
  },
  fieldSelector: {
    by: 'by',
    defaultPlaceholder: 'Select field...',
    defaultFixedLabel: 'Fixed'
  },
  bandExprSelector: {
    chooseOption: 'Choose option',
    customExpressions: 'Custom expressions',
    addExpression: 'Add expression',
    invalidSyntax: 'Invalid syntax',
    none: 'None',
    bandBy: 'band by',
    bands: 'Bands',
    bandLabels: {
      red: 'Red',
      green: 'Green',
      blue: 'Blue',
      alpha: 'Alpha'
    }
  },
  dataFreshness: {
    default: 'Default',
    minutes: '{number, plural, =0 {0 minutes} one {# minute} other {# minutes}}',
    hours: '{number, plural, =0 {0 hours} one {# hour} other {# hours}}'
  },

  dataFreshnessDialog: {
    title: 'Configure data sources freshness',
    prompt: 'Determine how up-to-date the data sources in your map are at its initial load',
    default: 'Default',
    defaultHelperText:
      'Default data freshness in Builder depends on the type of the data source and the specific data warehouse provider. <docLink>Learn more</docLink>',
    customLabel: 'Set data freshness to',
    label: 'Select freshness'
  },
  swapFooterDialog: {
    changeSource: 'Change source'
  },
  SwapSourceDialog: {
    title: 'Change data source',
    warnTitle: 'The new source can unset some aspects of the map.',
    warnContent:
      'By changing the map source, aspects such as widgets, SQL parameters, styles and interactions of the maps can be unset.'
  },
  swapConnectionDialog: {
    title: 'Change source connection'
  },
  sqlPanel: {
    title: 'SQL Editor',
    titleEdited: 'Edited',
    run: 'Run',
    stop: 'Stop',
    running: 'Running',
    ready: 'Ready',
    clearWidgetsConfirmationDialog: {
      title: 'Widgets applied',
      content: 'If you run the new query, all the widgets you applied on this dataset will be removed. Are you sure?'
    },
    buttonsLabels: {
      enterFullscreen: 'Enter full screen',
      exitFullscreen: 'Exit full screen',
      maximize: 'Maximize',
      minimize: 'Minimize',
      close: 'Close',
      connection: 'Connection used',
      spatial: {
        geom: 'Geometry',
        h3: 'H3',
        quadbin: 'Quadbins'
      }
    },
    errorDialogTitle: 'SQL error',
    createTable: 'Create table from query',
    platformLimitError:
      'The result of this query exceeds the platform limit. As a way to load the data in the map, try applying a filter, a limit, or creating a table from your query.',
    queryTooLong:
      'This query is taking too long. As a way to load the data in the map, try applying a filter, a limit, or creating a table from your query.',
    invalidGeoColumn: 'The geospatial support selected and the one used in the query are different.',
    spatialPopper: {
      title: 'Spatial data type',
      suggestedAddon: '(suggested)',
      hint: 'Learn more about data types at <a>docs.carto.com</a>',
      selectIndex: 'Select {type} {suffix}',
      manualOverrideSuffix: `(manually)`
    },
    spatialTypePrefix: 'Spatial type:',
    spatialIndexSuggestedHint:
      'The spatial data type has been automatically selected based on your query. Please review before running.',
    spatialTypeAutoSelectTooltip:
      'The spatial type will be auto-selected based on your query. Redefine it if required in the layers panel after running.'
  },
  shareDialog: {
    advance: {
      title: 'Developers and embedding',
      applicationDevelopment: 'Application development',
      mapEmbedding: 'Map embedding'
    },
    editorsCanEdit: '<role>Editors</role> can edit',
    customizeYourApp: "Customize your map's initial view with URL parameters. Learn more in our <a>documentation</a>.",
    copyDisabledTooltip: 'Share your map with others to enable this option',
    allowEditing: 'Allow editing this map',
    altTitle: 'Publish',
    publicOption: 'Public map',
    publicDesc: 'Visible to anyone with the public share link',
    link: 'Share Link',
    developers: 'Developers',
    copy: 'Copy public share link',
    copied: 'Copied!',
    share: 'Or share map',
    embedThisMap: 'Embed code',
    embedCopyAndPasteInfo:
      'Copy the HTML code below to embed the map. More details in our <docLink>documentation</docLink>',
    generatingHtml: 'Generating HTML ...',
    copyInput: 'Copy',
    mapID: 'Map ID',
    mapIDCopyAndPasteInfo: 'Learn how to develop an app from CARTO Builder in our <a>documentation</a>.',

    generatingMapId: 'Generating Map ID ...',
    mapToken: 'Map token',
    tokenCopyAndPasteInfo:
      'Learn how to use it <a href="https://docs.carto.com/react/guides/authentication-and-authorization/#public-applications" target="_blank" rel="noopener noreferrer">here</a>.',
    generatingToken: 'Generating Map Token ...',
    mapTokenCopyAndPasteInfo: 'Copy and paste this Map token into your public applications to use this map.',
    mapTokenDocs: 'How to use map token',
    nonPublicSuccessInfo:
      'Notice: The shared and embedded versions of this map (apps included) are no longer available. You can make it public at any time.',
    nonPublicSuccessInfoLink: 'Learn more',
    errorMessage: 'Oops! Something went wrong and was unable to complete your request.',
    protectWithPassword: 'Password protect',
    addPassword: 'Add password',
    notShared: 'The map has not yet been shared with the selected users or groups',
    notSharedDescription: 'If you close the dialog now, the map will keep the current permission level.',
    confirmationAlert: {
      entireOrganization: {
        title: 'Do you want to share the map with the entire organization?',
        description:
          'All users at your organization will be able to view the map and you will lose the current specific sharing settings.',
        confirmShareWithAll: 'Share with all'
      },
      private: {
        title: 'Do you want to make this map private?',
        description: 'Only you will be able to view the map and you will lose the current specific sharing settings.'
      },
      public: {
        title: 'Do you want to make this map public?',
        description:
          'Everyone with the link will be able to view the map and you will lose the current specific sharing settings.'
      }
    },
    usersGroupsSelector: {
      notFound: 'No users or groups matching your search criteria',
      notFoundOnlyUsers: 'No users matching your search criteria'
    }
  },
  mapPublishing: {
    pendingUpdate: "You haven't published the latest changes made",
    lastPublished: 'Last published',
    openPublicMap: 'Open public map',
    openSharedMap: 'Open shared map',
    publishingMap: 'Publishing map...',
    error: `There was an error publishing the map. Please try again and contact us if the error persists.`,
    publish: 'Publish',

    updatedSuccessfully: 'Your published map is up to date!'
  },
  takeOverRequestDialog: {
    titleForced: 'Someone else is editing this map',
    titleVoluntary: 'Take over editing?',
    message1:
      'The map is currently being edited by {user}. You can request to take over the editing process or access the map in view mode.',
    message2: 'In <strong>{timer} sec.</strong> the request will be automatically approved.',
    viewOnly: 'View only',
    takeOverEditing: 'Take over editing',
    requestingTakeOver: 'Requesting take over...',
    deniedTitle: 'Take over editing denied',
    deniedMessage: 'The current editor {user} has denied your request to take over.',
    approvedTitle: 'Take over editing approved',
    approvedMessage: 'Now you can edit the map.'
  },
  takeOverResponseDialog: {
    title: 'Someone requested editing control',
    message1: `Another editor (<strong>{user}</strong>) is requesting to take control to edit this map. If you approve their request, all your changes will be saved and you will be switched to view mode.`,
    message2: `In <strong>{timer} sec.</strong>  this request will be automatically approved.`,
    deny: 'Deny take over',
    approve: 'Approve take over',
    savingChanges: 'Saving changes',
    mapSavedTitle: 'Your changes were saved',
    mapSavedMessage: 'This map is now in view mode'
  },
  takeOverCanceledDialog: {
    title: 'Request for edition cancelled',
    message1: `<strong>{user}</strong> has finally cancelled its request for edition on this map.`,
    message2: `You can continue working on the map.`,
    understood: 'Understood'
  },
  toolBar: {
    invalidGeometry: 'Mask geometries must not have self-intersection',
    mapView: 'Map view',
    mapViewNormal: 'Normal view',
    mapView3d: '3D view',
    shape: 'Shape',
    closeLeftPanel: 'Close left panel',
    closeRightPanel: 'Close right panel',
    closeLeftPanelMobile: 'Close top panel',
    closeRightPanelMobile: 'Close bottom panel',
    measureTool: 'Measure distance',
    measureToolInfo: 'Click on the map to trace the line you want to measure. Double click or Esc to stop measuring',
    measureTotalDistance: 'Total distance',
    dualMapTooltip: {
      enter: 'Switch a dual map view',
      exit: 'Exit dual map view'
    },
    mapTitle: {
      label: 'Map title',
      placeholder: 'type a title'
    },
    mapDescription: {
      label: 'Map description',
      placeholder: 'type a description',
      helper: 'Max 800 characters allowed'
    },
    visibilityShapesTooltip: {
      show: 'Show shapes',
      hide: 'Hide shapes'
    },
    rightPaneDisabledTooltip: 'No widget or SQL parameters added',
    rightPaneWidgets: 'Widgets',
    rightPaneParameters: 'Parameters',
    rightPaneWidgetsAndParameters: 'Widgets and Parameters',
    rightPaneTabs: {
      widgets: 'Widgets',
      parameters: 'Parameters'
    }
  },
  layerBlending: {
    title: 'Layer Blending',
    optionAdditive: 'Additive',
    optionNormal: 'None',
    optionSubtractive: 'Subtractive'
  },
  layerActions: {
    zoomToLayerError: 'Zoom to extent is not supported for this layer'
  },
  addSources: {
    title: 'Add source to map',
    dataExplorer: 'Data Explorer',
    tableOrTileset: 'Table / Tileset',
    customQuery: 'Custom Query (SQL)',
    addCustomQuery: 'Add Custom Query (SQL)',
    sql: 'SQL',
    typeYourQuery: 'Type your own query',
    importFile: 'Import file',
    connections: 'Connections',
    fromConnections: 'From your connection',
    fromDataObservatory: 'From Data Observatory',
    selectConnection: 'Select an available connection',
    noConnection: 'No connection configured yet',
    lockedConnection: 'The connection cannot be changed in this process',
    switchConnection: 'Switch connection',
    learnSpatialSql: 'Use the Analytics Toolbox',
    manageConnections: 'Manage Connections',
    loading: 'Loading data source',
    analyzing: 'Analyzing...',
    minATRequiredVersion:
      'This analysis requires an Analytics Toolbox for {providerName} version higher than {minRequiredVersion}.',
    dataObservatory: {
      title: 'Data Observatory',
      accessIn: {
        disabled: {
          title: 'This sample is not available in Snowflake, Redshift or Postgres',
          description: 'Please select your CARTO Data Warehouse connection to add this sample to the map.'
        },
        request: {
          title: 'Access requested',
          description:
            'You will be able to add this subscription to the map using this connection as soon as your request has been fulfilled.'
        }
      }
    },
    button: {
      addSource: 'Add Source',
      addSourceFrom: 'Add source from...'
    },
    tooLarge: {
      title: 'The selected table is too large',
      link: 'create a tileset',
      message: "It'll be added as SQL query with a filter. You can also {actionLink} to visualize the whole table.",
      add: 'Add source as SQL query'
    },
    geoColumnNotFound: {
      title: "We couldn't find a geometry or spatial index column in this table",
      link: 'documentation',
      message: 'Check our {actionLink} to learn about supported geospatial data types.'
    },
    spatialIndex: {
      title: 'This table contains a {resource} index',
      link: 'Learn more',
      message: 'This kind of table can be optimized for better performance and reduced processing cost. {actionLink}'
    },
    optimizationAvailable: {
      title: "This table isn't optimized and can be slow.",
      link: 'Use Data Explorer to optimize it',
      message: 'Creating clusters/indexes based on geospatial data may improve performance. {actionLink}'
    },
    optimizationMandatory: {
      title: 'This table is not ready for visualization.',
      link: 'Use Data Explorer to prepare it',
      message: '{actionLink}'
    },
    rasterNotSupported: {
      title: 'This table contains raster data',
      link: 'Learn more',
      message: 'Raster tables are still not supported in this context'
    },
    rasterWithoutStats: {
      title: 'This raster lacks the necessary band statistics for map visualization in Builder'
    },
    uploadFiles: {
      button: 'Upload Files',
      formats: 'Upload {CSV}, {Json} or {GeoJSON}.',
      link: 'supported file formats.',
      dropFile: 'Drag & drop your file here',
      browse: 'Browse',
      progressFileName: 'File {name}',
      progressPercentage: '{percentage}%',
      notSupported: `File “{filename}” is not supported`
    },
    import: {
      changeFile: 'Edit details',
      importAndAddSource: 'Import and Add source',
      importingDescription:
        'This process might take a bit to complete, the data source will be added to the map as soon as it finishes.',
      importingError: 'An error ocurred.'
    },
    noSourcesAdded: 'No sources added yet',
    analysisNotApplicable: 'This analysis can\'t be applied to a data source filtered by date."',
    connectionNotShared: `This connection is not shared with all the editors so it can't be used in this map unless you modify this map's sharing settings.`
  },
  widgets: {
    title: 'Widgets',
    noWidgetsAdded: 'No widget added yet',
    newWidget: 'New widget',
    addWidget: 'Add widget',
    selectField: 'select a field',
    applyOperation: 'Apply Operation',
    formula: 'Formula',
    category: 'Category',
    pie: 'Pie',
    histogram: 'Histogram',
    timeseries: 'Time Series',
    table: 'Table',
    range: 'Range',
    columnOptions: 'Data',
    selectColumns: 'Select columns',
    selectColumnsAll: 'All',
    selectColumnsNone: 'None',
    data: 'Data',
    metric: 'Metric',
    operation: 'Operation',
    sqlExpression: 'SQL Expression',
    customAggregation: 'Custom aggregation',
    customAggregationPlaceholder: 'AVG(column_a) / AVG(column_b) * 100',
    displayOptions: 'Display options',
    formatting: 'Formatting',
    note: 'Note',
    markdownNotePlaceholder: `This is an example of note.\n\nYou can write your text here. You can use *italic* or **bold** to highlight words.`,
    collapsible: 'Collapsible widget',
    crossFiltering: 'Cross-filtering',
    crossFilteringTooltip:
      'Enable cross-filtering to allow users to filter a widget and its related widgets and layers linked to the same data source.',
    autoCollapse: 'Automatically collapse when layers are not visible',
    typeANote: 'type a note',
    widgetType: 'Widget type',
    widgetOptions: 'Widget options',
    copyId: 'Copy widget ID',
    rename: 'Rename',
    deleteWidget: 'Delete widget',
    aggregationColumn: 'Aggregation Column',
    buckets: 'Buckets',
    noWidgets: 'No widget added',
    addDataSourceBeforeAdding: 'Please, add a compatible data source before adding a widget.',
    loadingDataSources: "Loading data sources, please wait until they're loaded to add a widget.",
    backToMap: 'Back to map',
    aggregateBy: 'Aggregate by',
    groupDateTypes: {
      year: 'year',
      month: 'month',
      week: 'week',
      day: 'day',
      hour: 'hour',
      minute: 'minute',
      second: 'second'
    },
    timeSeries: {
      multipleSeries: 'Multiple series',
      multipleSeriesTooltip:
        'Add more series by splitting your metric based on a category field, or by adding further metrics.',
      splitBy: 'Split by',
      splitByNote: 'Up to 10 series will be automatically selected. These will be those with the dominant values.',
      addMetrics: 'Add metrics',
      allRemovedNote: 'Add up to 10 metrics.',
      metricLimitTooltip: 'Metrics limited to 10',
      interval: 'Interval',
      notAvailableTooltipOther: 'Only one Time Series Widget per map is allowed',
      notAvailableTooltipNoTimeColumn: 'No valid timestamp column available'
    },
    playPause: 'Animation controls',
    filterByViewport: 'Filter by viewport',
    global: 'global',
    behavior: 'Behavior',
    behaviorNote:
      'Global widgets show values from the entire data source. Widgets filtered by viewport show values from the features located within the map extent.',
    animationWithGlobalMode: 'Animation controls are not available in global mode.',
    customMin: 'Custom min. value',
    customMax: 'Custom max. value',
    uniqueId: 'Unique ID',
    notAvailableForSpatialIndexes: 'Not available for aggregated layers',
    tableWidgetEmptyCase: 'No columns matching your search',
    tableWidgetSearchPlaceholder: 'Search in {widgetTitle}',
    tableWidgetSearch: 'Search',
    uniqueIdNote: 'A unique ID is required for precise widget calculations.'
  },
  sqlParameters: {
    rename: 'Rename',
    edit: 'Edit parameter',
    remove: 'Remove control',
    values: 'Values',
    newValue: 'Add new value',
    addedValues: 'Added values',
    defaultValues: 'Default values',
    allValues: 'All values',
    noValuesMatching: 'No values matching your search criteria.',
    editSuccessNotification: 'Changes saved successfully.',
    emptyResultError:
      'This parameter combination produces an empty result on {layerCount, plural, one {layer} other {layers}} {layers}. Please note that the displayed features are from the previous selection.',
    notUsedInfo:
      'This parameter is not being used in any data source. Add {placeholder} manually to at least one query to enable this control.',
    hints: {
      start: 'Start SQL name',
      end: 'End SQL name',
      generic: 'SQL name'
    },
    sourcesTooltip: 'Used on {count} data sources',
    noSourcesTooltip: 'Not used on any source yet',
    text: {
      selectValue: 'Select value',
      customValue: 'Custom value'
    },
    createDialog: {
      title: 'Create a SQL Parameter',
      titleEdit: 'Edit SQL Parameter',
      introPage: {
        msg: {
          1: 'SQL Parameters are placeholders that can be used on any SQL Query data source in Builder.',
          2: `After creating a new SQL Parameter, it needs to be added manually to one or more SQL Query data sources. Once added, the actual value for the parameter can be defined through a control UI in the right side panel's 'Parameters' tab.`,
          3: 'Learn more about SQL Parameters in our <link>Documentation</link>.'
        }
      },
      selectTypePage: {
        prompt: 'Select the type of parameter:',
        dates: {
          label: 'Date',
          description:
            'Pick start and end dates from a calendar that can be used to filter by a date range in your query.'
        },
        category: {
          label: 'Text',
          description: 'Type or select values from a predefined list that will be interpreted as strings in your query.'
        },
        numeric: {
          label: 'Numeric',
          descriptionNotYet: 'This type of parameter will be available soon.',
          description:
            'Use a simple or range slider to retrieve a unique or a pair of numeric values to be used in your query.'
        }
      },
      categoryPage: {
        subtitle: 'Text parameter',
        prompt: 'Type or select values from a predefined list that will be interpreted as strings in your query.',
        displayNamePlaceholder: 'Choose a name, e.g.: "Type of event"',
        sqlName: {
          label: 'SQL name',
          placeholder: 'Choose a SQL name, like {{event_type}}',
          helpText:
            'Use the SQL name to place the parameter in your query. It will be replaced by an array of strings from the values picked in the selector. <link>Learn more</link>'
        },
        values: {
          manual: 'Add manually',
          fromSource: 'Add from a source',
          availableValues: 'Available values',
          valuePlaceholder: 'Type a value',
          noValues: 'No values added yet.',
          removeAll: 'Remove all',
          bottomHelp: 'These will be the values available in the parameter control.',
          valuesCount: '{count, plural, =0 {0 values} one {# value} other {# values}} added',
          columnPlaceholder: 'Select the key column',
          searchValues: 'Search values'
        }
      },
      datesPage: {
        subtitle: 'Dates parameter',
        prompt: 'Create variables for a date period (from / to). Useful for filtering a source with a date column.',
        displayNamePlaceholder: 'Choose a name, e.g.: "Event Date"',
        sqlNames: {
          startLabel: 'Start date SQL name',
          startPlaceholder: 'E.g.: {{date_from}}',
          endLabel: 'End date SQL name',
          endPlaceholder: 'E.g.: {{date_to}}',
          helpText:
            'Use the SQL name to place the parameters in your query. They will be replaced by dates from the values picked in the calendar. <link>Learn more</link>'
        },
        values: {
          startDateLabel: 'Start date',
          endDateLabel: 'End date',
          bottomHelp: 'The values above will be used as bounds for available dates in the calendar.'
        }
      },
      numeric: {
        subtitle: 'Numeric parameter',
        prompt: 'Use a simple or range slider to retrieve a unique or a pair of numeric values.',
        single: 'Simple slider',
        range: 'Range slider',
        displayNamePlaceholder: 'Chose a name, e.g.: "Buffer Size"',
        values: {
          label: 'Set values',
          minLabel: 'Min. value',
          defaultLabel: 'Default value',
          maxLabel: 'Max. value',
          defaultPlaceholder: 'Def.',
          minPlaceholder: 'E.g.: 0',
          valuePlaceholder: 'E.g.: 50',
          maxPlaceholder: 'E.g.: 100'
        },
        sqlName: {
          label: 'SQL name',
          placeholder: 'Choose a SQL name, e.g.: {{buffer_size}}',
          helpText:
            'Use the SQL name to place the parameter in your query. It will be replaced by an numeric value picked in the slider. <link>Learn more</link>'
        },
        sqlNames: {
          startLabel: 'Min SQL name',
          startPlaceholder: 'E.g.: {{min_size}}',
          endLabel: 'Max SQL name',
          endPlaceholder: 'E.g.: {{max_size}}',
          helpText:
            'Use the SQL name to place the parameter in your query. It will be replaced by an numeric value picked in the slider. <link>Learn more</link>'
        },
        scale: {
          label: 'Scale',
          continuous: 'Continuous',
          discrete: 'Discrete',
          step: 'Step increment',
          stepPlaceholder: 'E.g.: 5',
          stepHint: 'Interval between selectable values.'
        }
      },
      common: {
        names: 'Naming',
        values: 'Values',
        displayName: 'Display name',
        createParameter: 'Create parameter'
      }
    },
    editDialog: {
      placeholdersChangedWarning: {
        title: 'Some SQL Query sources might be affected',
        message1:
          'When updating the "SQL name" of the parameter, ensure to update the linked SQL Query sources with the new placeholder:'
      }
    },
    removeDialog: {
      title: 'Remove SQL parameter control',
      msg: {
        1: 'The <strong>{name}</strong> parameter control will no longer be displayed.',
        2: 'You are going to remove the control for the parameter {name}.{br}The parameter will keep its current value in all queries where it has been utilized until it is manually removed from them.'
      },
      alert:
        'In order to actually delete the parameter from the source, please make sure you have removed all references to {placeholder} from all SQL queries.',
      confirmButton: 'Remove control'
    }
  },
  datasetItem: {
    providerPlaceholder: 'CARTO',
    records: `{records, plural, =0 {0 RECORDS} one {# RECORD} other {# RECORDS}}`,
    pixels: `{records, plural, =0 {0 PIXELS} one {# PIXEL} other {# PIXELS}}`,
    showDataTable: 'Show data table',
    openSql: 'Open SQL Editor',
    closeSql: 'Close SQL Editor',
    addLayer: 'Add layer',
    deleteSource: 'Delete source',
    loadingSource: 'Loading source...',
    showError: 'Show error details',
    actionsTooltip: 'More options',
    sqlQueryApplied: 'SQL',
    sqlQueryAppliedTooltip: 'SQL query applied',
    error: 'Error',
    sqlWarning: 'SQL warning',
    cancelled: 'Cancelled',
    spatialIndexTooltip: 'This source contains a {index} index',
    rename: 'Rename',
    refreshDataset: 'Refresh data source',
    queryThisTable: 'Query this table',
    changeDataSource: 'Change data source',
    changeSourceConnection: 'Change source connection',
    oauthErrorBQ:
      'We lost access to BigQuery. Please repair this connection using your Google credentials and refresh the map or data sources.',
    oauthErrorSnowflake:
      'We lost access to Snowflake. Please repair this connection using your Snowflake credentials and refresh the map or data sources.'
  },
  mapStyle: {
    layers: 'Map Layers'
  },
  dataTableModal: {
    title: 'Data table'
  },
  deleteSourceModal: {
    title: 'Delete Source',
    info: 'You are going to delete the following data source. ',
    layers: ' It will affect {layers, plural, =0 {0 layers} one {# layer} other {# layers}}: {layerNames}.',
    deleteButton: 'Delete source',
    cancelButton: 'Cancel'
  },
  loading: {
    dataSources: 'Loading data sources…',
    tiles: 'Loading map tiles'
  },
  errors: {
    404: {
      title: 'Map not found',
      subtitle: 'The map you requested could not be found.'
    },
    mandatoryValue: 'This field is mandatory.',
    invalidSqlNameFormat: 'Placeholders start with {{ and end with }}.',
    invalidSqlNameChars: 'Use only alpha-numeric characters (a-z, 0-9 and _).',
    invalidSqlNameEmpty: 'Enter name between {{ and }}.',
    sqlParameterConflict: 'Name already used.',
    invalidDate: 'Invalid date, use YYYY-MM-DD format.',
    invalidNumber: 'Invalid number.',
    endBeforeStart: 'End must be after start.',
    minMaxError: 'Max must be greater than min.'
  },
  layerQuota: {
    notice: 'Consider hiding layers',
    message: 'Although you can keep showing more layers please keep in mind performance may be degraded.',
    noteText:
      'Depending on your computer’s capacity and the complexity of the layers, performance issues might appear. For a smoother experience we recommend working with {maxLayers} or less layers.'
  },
  tabsPanels: {
    layer: 'Layers',
    filter: 'Widgets',
    interaction: 'Interactions',
    map: 'Basemaps',
    legend: 'Legend',
    ai: 'CARTO AI Agent'
  },
  baseMap: {
    carto: {
      title: 'CARTO'
    },
    googleMaps: {
      title: 'Google Maps'
    },
    custom: {
      title: 'Custom'
    },
    typeNotAvailable: "This basemap type is no longer accessible. Contact your organization's Admin to restore access.",
    styleNotAvailable: "The basemap is no longer accessible. Contact your organization's Admin to restore access."
  },
  analysis: {
    startAnalysis: 'Add SQL Analysis',
    button: 'Analysis',
    addSources: 'Please, add a compatible source before creating an analysis',
    addSourceDialog: {
      title: 'Select the second source for SQL Analysis'
    },
    catalog: {
      intersect: {
        title: 'Intersect and aggregate',
        description: 'Find overlapping geometries from a second source and aggregate their values in the base source.'
      },
      addColumn: {
        title: 'Add columns from second source',
        description: 'Join columns from a second source using a shared column value.'
      },
      geocode: {
        title: 'Geocode',
        description: 'Use street addresses, city names, or other location text to create point geometries.'
      },
      buffer: {
        title: 'Create Buffers',
        description: 'Create buffers with a fixed distance from points, lines or polygons.'
      },
      columnValue: {
        title: 'Filter by column value',
        description: 'Keep or discard rows based on column values.'
      },
      centroid: {
        title: 'Compute centroids',
        description: 'Calculate the single or categorized centroid from a set of geometries.'
      },
      clusteringKMeans: {
        title: 'Clustering K-Means',
        description: 'Find a custom number of geospatial clusters from a set of points.'
      },
      tradeAreas: {
        title: 'Trade areas',
        description: 'Find areas within a custom time range around your points.'
      }
    },
    modal: {
      title: 'Add SQL Analysis',
      tableTitle: 'Persist query to a table',
      steps: {
        analysis: 'Analysis',
        settings: 'Settings',
        destination: 'Destination',
        confirmation: 'Confirmation'
      },
      catalog: {
        title: 'Select the analysis you want to add:'
      }
    }
  },
  legendManager: {
    title: 'Legend',
    noLayers: 'Add a layer before configuring a legend',
    hide: 'Remove layer from map layer list',
    show: 'Add layer to map layer list',
    hiddenLegendCaption: 'Legend hidden for this layer',
    hideLegend: 'Hide legend',
    showLegend: 'Show',
    hideLegendTooltip: "Hide this layer's legend",
    showLegendTooltip: "Show this layer's legend",
    entryType: {
      color: 'Color',
      strokeColor: 'Stroke color',
      colorBasedOn: 'Color based on',
      strokeColorBasedOn: 'Stroke color based on',
      radiusBasedOn: 'Radius range by',
      customMarkersBasedOn: 'Custom markers based on',
      rasterRgb: 'Raster bands combination'
    },
    changeLabel: 'Change text label',
    changeTitle: 'Change title',
    resetOverrides: 'Reset overrides',
    baseMapSelector: 'Basemap selector',
    limit: 'Limited to {num} categories',
    zoom: {
      title: 'Zoom level {range}',
      tooltip: 'This layer is only visible at certain zoom levels'
    }
  },
  aiManager: {
    title: 'CARTO AI',
    agent: {
      title: 'AI Agent',
      description:
        'Allow users to interact with an AI agent that provides responses and insights about the map. <docLink>Learn more</docLink>',
      created: 'CARTO AI Agent created!',
      disabled: 'CARTO AI Agent disabled!',
      error: 'Error creating AI Agent'
    },
    mapContext: {
      title: 'Map context',
      tooltip: 'Configure the AI Assistant first to have this functionality on the map.',
      placeholder: 'Enter the core details of your map here to orient the AI'
    },
    conversation: {
      title: 'Conversation starters',
      placeholder: 'Type a suggested prompt',
      prompts: 'prompts'
    },
    userGuide: {
      title: 'Add a User Guide',
      placeholder: 'Write an introductory message here'
    }
  },
  dynamicTiling: {
    outOfZoom: 'Some rows have been filtered at this zoom level. Zoom in to ensure you see all rows in the map.',
    tileTooLongTime:
      'Some parts of this map are taking longer than usual to load. Try zooming in for better performance.'
  },
  legendControl: {
    title: 'Layers',
    buttonTooltip: 'Show layer panel',
    basemap: 'Basemap selector'
  },
  passwordForm: {
    title: 'This map is protected with a password',
    placeholder: "Insert the map's password",
    iconTooltip: 'Toggle password visibility',
    button: 'Continue',
    contact: 'Contact the owner if you need to access this map',
    errors: {
      wrong: 'Wrong password',
      empty: 'Cannot be empty'
    }
  },
  searchAddress: {
    placeholder: 'Search location',
    cancelButton: 'Cancel search'
  },
  popupManager: {
    title: 'Pop-ups',
    noLayers: 'Add a layer before configuring pop-ups',
    disabledTooltips: {
      heatmapTile: 'Interactions are not supported for Heatmap layer type.'
    },
    popupsOnLayer: {
      disabled: 'None',
      clickOnly: 'Click',
      hoverOnly: 'Hover',
      clickAndHover: 'Click + hover'
    },
    fields: {
      empty: 'No field added yet',
      add: 'Add a field',
      addAll: 'Add all',
      fillAll: 'Fill all',
      removeAll: 'None',
      changeLabel: 'Change text label',
      resetOverrides: 'Reset overrides',
      selectedCount: '{count, plural, =1 {# field} other {# fields}}',
      filledCount: '{count} of {limit} fields'
    },
    moreOptions: 'More options',
    styles: {
      none: 'None',
      light: 'Light',
      lightWithHiFirst: 'Light with highlighted 1st value',
      dark: 'Dark',
      darkWithHiFirst: 'Dark with highlighted 1st value',
      panel: 'Info panel',
      groupPopups: 'Pop-up window',
      groupSlider: 'Right side slider',
      selectInteraction: 'Select interaction'
    },
    disabledForEvent: 'No {event} pop-up applied',
    formatting: 'Formatting',
    featureDetails: 'Feature details',
    toList: 'Switch to field list mode',
    toHTML: 'Switch to HTML mode',
    template: {
      htmlEditor: 'HTML editor',
      save: 'Save',
      lostChangesWarning:
        'The custom HTML that is currently applied will be lost when switching back to the standard mode.',
      lostChangesContinue: 'Do you want to continue?',
      learnHowToCustomize: 'Learn how to customize your popups in our <link>Documentation</link>',
      invalidFieldCount:
        'The limit of 5 properties has been exceeded. Check the code and remove them until it reaches the allowed limit.',
      clickPopup: 'Click popup',
      hoverPopup: 'Hover popup'
    }
  },
  datasetSelector: {
    placeholder: 'Select a data source',
    noDatasets: "There aren't any valid sources in the map"
  },
  controls: {
    zoomIn: 'Zoom in',
    zoomOut: 'Zoom out',
    roundedZoom: 'Current rounded zoom value',
    basemap: 'Basemap selector'
  },
  settingsToggles: {
    controlCacheTime: 'Refresh data sources',
    exportViewportData: 'Export viewport data',
    exportPDF: 'Download PDF Report',
    showMeasureDistanceTool: 'Measure tool',
    showTitlePublicMap: 'Show title in public map',
    addressSearchBar: 'Search location bar',
    sqlParameterControls: 'SQL parameters controls',
    showDateControl: 'Date control',
    scrollWheelZoom: 'Scroll wheel zoom',
    featureSelectionTool: 'Feature selection tool',
    showPerformanceWarnings: 'Show performance warnings',
    showMyLocationButton: 'Focus map on the current location'
  },
  mapSettings: {
    title: 'Map settings for viewers',
    interactivity: 'Interactivity',
    navigation: 'Navigation',
    display: 'Display',
    notifications: 'Notifications',
    basemaps: 'Basemaps',
    props: {
      featureSelectionTool: 'Feature selection tool',
      sqlParameterControls: 'SQL parameters controls',
      exportViewportData: 'Export viewport data',
      exportPDF: 'Download PDF Report',
      showMeasureDistanceTool: 'Measure tool',
      showTitlePublicMap: 'Show title in public map',
      addressSearchBar: 'Search location bar',
      scrollWheelZoom: 'Scroll wheel zoom',
      showPerformanceWarnings: 'Show performance warnings',
      showMyLocationButton: 'Focus map on the current location',
      basemapsSelector: 'Basemap selector'
    },
    propsTooltips: {
      featureSelectionTool:
        'Enable this for user-driven data filtering with rectangle, circle, or custom polygon tools.',
      sqlParameterControls: 'Allow users to dynamically update data sources using parameter controls.',
      exportViewportData: 'Allow users to export data from the viewport extent as CSV format.',
      exportPDF: 'Allow users to download a static PDF report of the current map view.',
      showMeasureDistanceTool: 'Allow users to measure point-to-point distances on the map.',
      showTitlePublicMap: 'Display the title header in public or embed map.',
      addressSearchBar: 'The search location bar allow viewers to quickly find places using addresses or coordinates.',
      scrollWheelZoom: 'Let users zoom in and out using the mouse scroll wheel.',
      showPerformanceWarnings: 'Allow performance warning notifications in the application.',
      showMyLocationButton: 'Allow users to center the map on their current location.',
      basemapsSelector: 'Allow viewers to switch between basemaps'
    }
  },
  loginPrompt: {
    title: "Oops! You can't access this map",
    content:
      "Use your CARTO credentials or sign up to access this map. If the map exists and is shared with you, you'll be automatically redirected to it.",
    contentEmbedded:
      'Login or sign up using your CARTO credentials in a new window. Then, refresh this page to access the map.'
  },
  cookiesPrompt: {
    title: 'Allow CARTO to use its cookies',
    titleDenied: 'Access denied to CARTO cookies',
    content:
      'In order to view this embedded map, we need to verify your CARTO authentication session, stored in your browser cookies. Please allow this access to continue.',
    contentDenied:
      'We need to verify your CARTO authentication session, stored in your browser, to access this embedded map. In some browsers, like Safari or Firefox, you might need to login first in CARTO and then retry.',
    goToCarto: 'Go to CARTO'
  },
  noPermissionsPrompt: {
    title: "Oops! You can't access this map",
    content:
      'It looks like this map is currently not shared with you. Please contact the map owner if you want to get access.'
  },
  ai: {
    title: 'CARTO AI',
    chat: {
      placeholder: 'Message AI Agent...',
      helpTitle: 'How can I help you?',
      takingOver: "I'm taking over now; this will just take a moment.",
      inappropriate: 'Inappropriate',
      appropriate: 'Appropriate'
    },
    actions: {
      set_map_center_and_zoom: 'Changing map position',
      get_formula_widget: 'Reading formula widget',
      get_category_widget: 'Reading category widget',
      get_histogram_widget: 'Reading histogram widget',
      get_time_series_widget: 'Reading time series widget',
      filter_category_widget: 'Filtering by category',
      filter_histogram_widget: 'Filtering by histogram',
      filter_range_widget: 'Filtering by range',
      set_layers_visibility: 'Changing layer visibility',
      analyze_map_viewport: 'Analyzing map viewport',
      get_map_coordinates: 'Getting map coordinates',
      get_spatial_filter: 'Getting spatial filter'
    },
    quota: {
      threadLimit: 'Monthly quota exceeded at your organization account.',
      threadLimitShort: 'Monthly quota exceeded.',
      tokenLimit:
        'The conversation limit for this session has been reached. To start a new session, please delete the current chat.',
      threadLimitDialogHeader: 'Monthly quota exceeded',
      threadLimitDialogContent: `The CARTO AI Agent feature on shared maps has reached its monthly limit and will be disabled until the next
        period.

        The AI Agent quota resets monthly, with your organization’s access renewing on <strong>{date}</strong>.
        
        For more details, please contact your CARTO administrator or refer to our documentation
      `
    },
    deleteChat: 'Delete chat'
  }
}
